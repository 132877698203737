import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import Section from "../components/Section";
import styled from "styled-components";
import Img from "gatsby-image";
import SEO from "../components/SEO";
import { below } from "../styles/Breakpoints";
import ShareIcons from "../components/ShareIcons/ShareIcons";

const StyledNewsPost = styled.div`
  padding-bottom: 100px;
  max-width: 600px;
  width: 100%;
  ${below.mobileL`
    padding-bottom: 50px;
  `}

  h1 {
    font-size: 2rem;
    font-size: clamp(1.5rem, (1rem + 3vw), 2rem);
    @supports not (font-size: clamp(1.5rem, 1rem + 3vw, 2rem)) {
      ${below.mobileL`
        font-size: 1.5rem;
      `}
    }
    text-transform: initial;
    margin-bottom: 1rem;
    text-align: left;
  }
`;

export default function NewsPost({ data: { post }, location }) {
  const url = location.href;

  return (
    <>
      <SEO
        title={post.title}
        image={post.mainImage?.asset?.fluid?.src}
        location={location}
        description={post._rawBody[0].children[0].text}
      />
      <Section fullScreen flow>
        <StyledNewsPost>
          <h1>{post.title}</h1>
          <div style={{ maxWidth: "600px", width: "100%" }}>
            {post.mainImage && <Img fluid={post.mainImage.asset.fluid} />}
          </div>
          <div className="post-body">
            <BlockContent
              blocks={post._rawBody}
              projectId="hq5iqyv5"
              dataset="production"
            />
          </div>
          <div className="post-meta-container">
            <ShareIcons title={post.title} url={url} />
            <span>
              Posted by{" "}
              {/* TODO add link to either author's other posts or social media */}
              <a href="#" className="author-name">
                {post.author?.name}
              </a>{" "}
              on <span>{post._updatedAt}</span>
            </span>
          </div>
          <div className="post-divider"></div>
        </StyledNewsPost>
      </Section>
    </>
  );
}

export const newsPostQuery = graphql`
  query($slug: String!) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      _id
      author {
        name
      }
      _rawBody
      _updatedAt(formatString: "MMMM DD, YYYY")
      mainImage {
        asset {
          fluid(maxWidth: 600) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
